import { graphql } from "gatsby"
import React from "react"
import ChangeHeaderInView from "../components/changeHeaderinView"
import HeroBanner from "../components/heroBanner"
import Layout from "../components/layout"
import NavigationSectionWrapper from "../components/navigationSectionWrapper"
import ProductDetails from "../components/productDetails"
import ProductFeatures from "../components/productFeatures"
import SecondaryNavigation from "../components/secondaryNavigation"
import Seo from "../components/seo"
import Testiomnal from "../components/testiomnal"
import CurrentSectionContextWrapper, { createSections } from "../context/currentSectionContext"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"
import { resolveToFeatureInfo, resolveToTestiomial } from "../utils/dataFetchers"

export default function Product({ data }) {
    const { isJapanese, switchLanguage } = useLanguage()
    const englishSections = data.product.tabs.map(tab => tab.title.toLowerCase())
    const japaneseSections = data.product.tabs.map(tab => tab.titleJp.toLowerCase())
    const sections = isJapanese ? createSections([...japaneseSections]) : createSections([...englishSections])
    return (
        <CurrentSectionContextWrapper sections={sections}>
            <Layout>
                <Seo title={data.product.name} />
                <HeroBanner mobileImage={data.product.mobileFeaturedHeroImage} mobileVideo={data.product.mobileFeaturedHeroVideo} title={switchLanguage(data.product.headingJp, data.product.heading)} video={data?.product?.featuredVideoUrl} image={data?.product?.featuredImage} />
                <ChangeHeaderInView threshold={0.1} color={COLOR_STATES.DARK_NO_BG}>
                    <SecondaryNavigation sections={sections} />
                    <NavigationSectionWrapper name={sections[switchLanguage(japaneseSections[0], englishSections[0])]}>
                        <ProductFeatures info={resolveToFeatureInfo(data.product)} features={data.product.features} />
                    </NavigationSectionWrapper>
                    <NavigationSectionWrapper name={sections[switchLanguage(japaneseSections[1], englishSections[1])]}>
                        <ProductDetails cards={data.product.productCards} />
                    </NavigationSectionWrapper>
                    <NavigationSectionWrapper name={sections[switchLanguage(japaneseSections[2], englishSections[2])]}>
                        <Testiomnal testiomnal={resolveToTestiomial(data.product)} overline={switchLanguage(data.product.overlineQuoteJp, data.product.overlineQuote)} />
                    </NavigationSectionWrapper>
                </ChangeHeaderInView>
            </Layout>
        </CurrentSectionContextWrapper>
    )
}

export const query = graphql`
    query Product($id: String) {
        pageData: datoCmsOffering(id: { eq: $id }) {
            subtitle: shortenedName
            subtitleJp: shortenedNameJp
        }
        product: datoCmsOffering(id: { eq: $id }) {
            name
            nameJp
            mobileFeaturedHeroVideo
            mobileFeaturedHeroImage {
                gatsbyImageData
            }
            shortenedName
            shortenedNameJp
            featureTitle
            featureTitleJp
            featuredImage {
                gatsbyImageData
            }
            featuredVideoUrl
            featureDescription
            featureDescriptionJp
            heading
            headingJp

            tabs {
                id
                title
                titleJp
            }
            productCards {
                id
                caption
                captionJp
                title
                titleJp
                textLight
                isLaptopCard
                image {
                    gatsbyImageData
                }
                imageMobile {
                    gatsbyImageData
                }
                backgroundVideoUrl
                icon {
                    url
                }
            }
            features {
                ...Feature
            }
            overlineQuote
            ...Testiomnal
        }
    }
`
