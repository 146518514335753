import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { useLanguage } from "../hooks/useLanguage"
import VideoOrImage from "./videoOrImage"

export default function ProductDetails({ cards }) {
    const { switchLanguage } = useLanguage()
    const Cards = (
        <div className='cards w-100 flex flex-wrap'>
            {cards.map((card, index) => {
                if (card.isLaptopCard && index === 0) return <LaptopCard {...card} />
                return (
                    <div className={"card br-10 mb60 ratio-2-1 m-ratio-1-2 pos-rel overflow-hidden m-mb20 " + (index !== 0 ? " w-50-10 m-100 m-ml20 m-mr20  " : " m-br0 t-ratio-1-2 m-mb40 w-100 ") + (index === 1 ? " mr10 " : "") + (index === 2 ? " ml10 " : "")}>
                        <div className={"max-500  pos-rel z-2 pos-ab p60 " + (card.textLight ? "" : "text-white") + (index === 0 ? " m-p0  m-pt20 " : " m-p20")}>
                            <div className={"icon-wrapper mb20 pos-rel " + (index === 0 ? "  m-ml20 m-mr20" : "")}>{card.icon ? <img className='bg-image' alt='Product Icon' src={card.icon?.url} /> : ""}</div>
                            <h2 className={"m0 " + (index !== 0 ? " h3 " : "h2  m-pl20 m-pr20")}>{switchLanguage(card.titleJp, card.title)}</h2>
                            <div className={"caption mt20 balance " + (index === 0 ? "m-pl20 m-pr20" : "")}>{switchLanguage(card.captionJp, card.caption)}</div>
                        </div>

                        <VideoOrImage className={"bg-image m-br-08 " + (card.imageMobile ? "t-hide" : "")} image={card.image} videoWhen={card.backgroundVideoUrl} video={card.backgroundVideoUrl} alt='Product Image' />
                        <GatsbyImage alt='Mobile Product Image' className='t-show bg-image ' image={card.imageMobile?.gatsbyImageData} />
                    </div>
                )
            })}
        </div>
    )
    return (
        <>
            <div className='p60 m-p0 flex'>
                <div className='max-1600 w-100 ma flex'>{Cards}</div>
            </div>
        </>
    )
}

const LaptopCard = ({ image, icon, imageMobile, title, titleJp, caption, captionJp, textLight, backgroundVideoUrl }) => {
    const { switchLanguage } = useLanguage()
    return (
        <>
            <div className={"card t-flex t-flex-column br-10 ratio-2-1 m-ratio-1-15 pos-rel overflow-hidden t-ratio-1-2 m-mb20 w-100 "}>
                <div className={"max-500  pos-rel z-2 pos-ab t-pos-rel p60 " + (textLight ? "" : "text-white") + " m-p0  m-pt20 "}>
                    <div className={"icon-wrapper mb20 pos-rel  m-ml20 m-mr20"}>{icon ? <img className='bg-image' alt='Product Icon' src={icon?.url} /> : ""}</div>
                    <h2 className={"m0 h2  m-pl20 m-pr20"}>{switchLanguage(titleJp, title)}</h2>
                    <div className={"caption balance mt20 m-pl20 m-pr20"}>{switchLanguage(captionJp, caption)}</div>
                </div>

                <VideoOrImage className={"bg-image m-br-08 " + (imageMobile ? "t-hide" : "")} image={image} videoWhen={backgroundVideoUrl} video={backgroundVideoUrl} alt='Product Image' />
                <GatsbyImage alt='Mobile Product Image' className='t-show m-ratio-laptop mta block m-ml20 m-mr20' image={imageMobile?.gatsbyImageData} />
            </div>
        </>
    )
}
